<template>

   <div class="eon-app-frame">

      <div class="eon-app-header">
         <div class="app-logo" @click="loadPage(0, 'fade')" />
         <div class="btn-index" @click="loadPage(0, 'fade')" />
      </div>

      <div class="eon-app-body">

         <div class="preloader" :style="{ 'background-image': 'url(assets/pages/' + pages[getPrevPagePos] + '.png' }" />
         <div class="preloader" :style="{ 'background-image': 'url(assets/pages/' + pages[getNextPagePos] + '.png' }" />

         <transition :name="pageTransition">
            <div v-if="modelShowCurrent" class="eon-app-page" :class="{ 'dark-bg': currentPage === 0 }">

               <!-- PAGE : INDEX -->
               <div v-if="currentPage === 0" class="page-content" :style="{ 'background-image': 'url(assets/pages/' + pages[currentPage] + '.png' }" >
                  <div class="content-area">
                     <div class="hot-spot hs1" @click="loadPage(1, 'fade')" />
                     <div class="hot-spot hs2" @click="loadPage(3, 'fade')" />
                     <div class="hot-spot hs3" @click="loadPage(11, 'fade')" />
                     <div class="hot-spot hs4" @click="loadPage(18, 'fade')" />
                     <div class="hot-spot hs5" @click="loadPage(25, 'fade')" />
                     <div class="hot-spot hs6" @click="loadPage(34, 'fade')" />
                     <div class="hot-spot hs7" @click="loadPage(42, 'fade')" />
                     <div class="hot-spot hs8" @click="loadPage(44, 'fade')" />
                  </div>
               </div>

               <!-- PAGE : 42 -->
               <div v-else-if="currentPage === 42" class="page-content" :style="{ 'background-image': 'url(assets/pages/' + pages[currentPage] + '.png' }" >
                  <div class="content-area">
                     <a href="https://www.biometric.dk/" target="_blank">
                        <div class="hot-spot hs9" />
                     </a>
                  </div>
               </div>

               <!-- PAGE : 43 -->
               <div v-else-if="currentPage === 43" class="page-content" :style="{ 'background-image': 'url(assets/pages/' + pages[currentPage] + '.png' }" >
                  <div class="content-area">
                     <a href="https://www.biometric.dk/" target="_blank">
                        <div class="hot-spot hs10" />
                     </a>
                  </div>
               </div>

               <!-- PAGE : DEFAULT -->
               <div v-else class="page-content" :style="{ 'background-image': 'url(assets/pages/' + pages[currentPage] + '.png' }" />

            </div>
         </transition>

         <transition name="nav-button-transition">
            <div class="app-nav-button left" @click="loadPrevPage()" v-if="currentPage != 0" />
         </transition>
         <transition name="nav-button-transition">
            <div class="app-nav-button right" @click="loadNextPage()" v-if="currentPage < numberOfPages - 1" />
         </transition>

      </div>

   </div>

</template>



<script>
export default {
   data: () => ({
      numberOfPages: 51,
      pages: [],
      currentPage: 0,
      pageToLoad: 0,
      modelShowCurrent: true,
      pageTransition: "fade"
   }),
   created() {
      for (let i = 0; i < this.numberOfPages; i++) {
         this.pages[i] = i
      }
      document.addEventListener('keydown', this.keyDown)
   },
   computed: {
      getPrevPagePos() {
         return this.currentPage === 0 ? this.pages.length - 1 : this.currentPage - 1
      },
      getNextPagePos() {
         return this.currentPage === this.pages.length - 1 ? 0 : this.currentPage + 1
      }
   },
   methods: {
      loadPage(page, transition) {
         this.pageToLoad = page
         this.pageTransition = transition
         setTimeout(() => {
            this.modelShowCurrent = false
         }, 5);
         setTimeout(() => {
            this.currentPage = page
            this.modelShowCurrent = true
         }, 410);
      },
      loadPrevPage() {
         this.pageToLoad = this.currentPage === 0 ? this.pages.length - 1 : this.currentPage - 1
         this.loadPage(this.pageToLoad, 'slide-right')
      },
      loadNextPage() {
         if ( this.currentPage === this.pages.length - 1) {
            this.pageToLoad = 0
         }
         else {
            this.pageToLoad = this.currentPage + 1
         }
         this.loadPage(this.pageToLoad, 'slide-left')
      },
      keyDown (e) {
         if ( e.keyCode === 37 && this.currentPage != 0 ) {
            this.loadPrevPage()
         }
         if ( e.keyCode === 39 && this.currentPage < this.numberOfPages - 1 ) {
            this.loadNextPage()
         }
      },
   }
}
</script>



<style lang="scss">
@font-face {
   font-family: 'Raleway';
   font-style: normal;
   font-weight: 400;
   src: url('/public/static/fonts/RALEWAY-REGULAR.TTF') format('truetype');
}
@font-face {
   font-family: 'Raleway';
   font-style: italic;
   font-weight: 400;
   src: url('/public/static/fonts/RALEWAY-ITALIC.TTF') format('truetype');
}
@font-face {
   font-family: 'Raleway';
   font-style: normal;
   font-weight: 500;
   src: url('/public/static/fonts/RALEWAY-MEDIUM.TTF') format('truetype');
}
@font-face {
   font-family: 'Raleway';
   font-style: normal;
   font-weight: 600;
   src: url('/public/static/fonts/RALEWAY-SEMIBOLD.TTF') format('truetype');
}
@font-face {
   font-family: 'Raleway';
   font-style: normal;
   font-weight: 700;
   src: url('/public/static/fonts/RALEWAY-BOLD.TTF') format('truetype');
}

html, body, #app {
   height: 100%;
}

html {
   overflow: hidden;
   scroll-behavior: smooth;
}

body {
   background: #ffffff;
   color: #ffffff;
   font-family: 'Raleway', sans-serif;
   font-feature-settings: "lnum";
   font-variant-ligatures: no-common-ligatures;
   margin: 0;
   padding: 0;
}

.eon-app-frame {
   display: flex;
   flex-direction: column;
   height: 100%;
}

.eon-app-header {
   align-items: center;
   background: #2a2e33;
   box-sizing: border-box;
   display: flex;
   flex-shrink: 0;
   height: 85px;
   position: relative;
   width: 100%;

   > .app-logo {
      background-image: url(/public/assets/gfx/logo.svg);
      background-position: center center;
      background-repeat: no-repeat;
      cursor: pointer;
      height: 100%;
      margin-left: 35px;
      position: relative;
      width: 184px;
   }

   > .btn-index {
      background-image: url(/public/assets/gfx/index.svg);
      background-position: center center;
      background-repeat: no-repeat;
      cursor: pointer;
      height: 44px;
      margin-left: 35px;
      position: relative;
      width: 163px;
   }
}

.eon-app-body {
   flex-grow: 1;
   flex-shrink: 0;
   position: relative;
   width: 100%;
}

.eon-app-page {
   align-items: center;
   display: flex;
   height: 100%;
   justify-content: center;
   left: 0vw;
   position: absolute;
   top: 0;
   transition: 400ms cubic-bezier(0.4, 0.0, 0.2, 1);
   width: 100vw;

   &.dark-bg {
      background: #2a2e33;
   }
}

.page-content {
   /* 1280 x 720 */
   /* 1181 x 637 */
   /* 1771 x 954 */
   /* 2006 x 1082 */
   // 1700 x 918
   align-items: center;
   background-position: center center;
   background-repeat: no-repeat;
   background-size: contain;
   display: flex;
   height: 918px;
   justify-content: center;
   // max-height: calc(100vh - 85px);
   max-height: calc(100vh - 105px);
   // max-width: calc(100vw - 200px);
   max-width: calc(100vw - 220px);
   position: relative;
   width: 1700px;

   > .content-area {
      aspect-ratio: 1700/918;
      max-height: 100%;
      max-width: 100%;
      position: relative;

      @media (min-aspect-ratio: 1700/918) {
         height: 918px;
         width: auto;
      }

      @media (max-aspect-ratio: 1700/918) {
         height: auto;
         width: 1700px;
      }
   }
}

.hot-spot {
   background: #ffffff;
   border-radius: 15px;
   cursor: pointer;
   // height: 38%;
   height: 44%;
   opacity: 0;
   position: absolute;
   transition: 150ms cubic-bezier(0.4, 0.0, 0.2, 1);
   // width: 21.3%;
   width: 23.5%;

   // Padding effect for hot-spots
   padding-bottom: 1%;
   padding-left: 1%;
   padding-right: 1%;
   padding-top: 1%;
   margin-left: -1%;
   margin-top: -1%;

   // FOR TEST
   // background: red;
   // opacity: 0.5;

   &:hover {
      opacity: 0.15;
   }

   &.hs1 { left: 0%; top: 0%; }
   &.hs2 { left: 25.5%; top: 0%; }
   &.hs3 { left: 51.05%; top: 0%; }
   &.hs4 { left: 76.55%; top: 0%; }
   &.hs5 { left: 0%; top: 51.3%; }
   &.hs6 { left: 25.5%; top: 51.3%; }
   &.hs7 { left: 51.05%; top: 51.3%; }
   &.hs8 { left: 76.55%; top: 51.3%; }
   &.hs9 { left: 75.2%; top: 89.1%; height: 7%; width: 12.9%; }
   &.hs10 { left: 3.2%; top: 36.6%; height: 7%; width: 17.5%; }
}

.app-nav-button {
   align-items: center;
   background-color: #d4d5d6;
   background-repeat: no-repeat;
   background-position: center;
   background-size: 50px 50px;
   cursor: pointer;
   display: flex;
   height: 100%;
   justify-content: center;
   position: absolute;
   top: 0;
   transition: 400ms cubic-bezier(0.4, 0.0, 0.2, 1);
   width: 100px;

   &.left {
      background-image: url(/public/assets/gfx/icon_navigate_before.svg);
      left: 0;
   }

   &.right {
      background-image: url(/public/assets/gfx/icon_navigate_next.svg);
      right: 0;
   }

   &:hover {
      background-color: #ffffff;
   }
}

.preloader {
   height: 20px;
   left: -50px;
   position: absolute;
   top: 0;
   width: 20px;
}



.fade-enter-active, .fade-leave-active {
   opacity: 1;
}
.fade-enter, .fade-leave-to {
   opacity: 0;
}



.slide-left-enter-active, .slide-left-leave-active {
   opacity: 1;
}
.slide-left-leave-to {
   opacity: 0;
   transform: translateX(-100%);
}
.slide-left-enter {
   opacity: 0;
   transform: translateX(100%);
}



.slide-right-enter-active, .slide-right-leave-active {
   opacity: 1;
}
.slide-right-leave-to {
   opacity: 0;
   transform: translateX(100%);
}
.slide-right-enter {
   opacity: 0;
   transform: translateX(-100%);
}



.nav-button-transition-enter-active, .nav-button-transition-leave-active {
   opacity: 1;
}
.nav-button-transition-enter, .nav-button-transition-leave-to {
   opacity: 0;
}
</style>